.tab-nav {
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-nav .nav-link {
  padding: 1rem 1.5rem;
  margin: 0.5rem 1rem;
}
