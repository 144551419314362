:root {
  /* Bootstrap vars */
  --bs-font-sans-serif: "Rubik", sans-serif !important;

  /* Icon font family */
  --theme-font-icon: "Rubik";
  --theme-font-icon-style: "normal";

  /* Avatar */
  --theme-avatar-size: 90px;

  @each $breakpoint, $value in $grid-breakpoints {
    --theme-breakpoint-#{$breakpoint}: #{$value};
  }
}

.navbar-brand {
  --bs-navbar-brand-font-size: 1.8rem !important;
}
